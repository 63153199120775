// Соответствие демо-странцы Styleguidist к борду из Styleguide.
const figmaBoardToStyleguidistComponentMap: Record<string, string[]> = {
    // Раздел Web

    // AccordionForm [ru/en]
    '/#!/Components/AccordionForm?styleguideLive=true': ['37916:39291', '73747:3338'],
    // AccordionView [ru/en]
    '/#!/Components/AccordionView?styleguideLive=true': ['35237:8', '73747:4829'],
    // Alerts [ru/en]
    '/#!/Components/Alerts?styleguideLive=true': ['14376:31266', '73747:6112'],
    // Amount [ru]
    '/#!/Components/Amount?styleguideLive=true': ['75188:15479'],
    // Buttons [ru/en]
    '/#!/Components/Buttons?styleguideLive=true': ['74225:10269', '73747:9097'],
    // Calendar [ru/en]
    '/#!/Components/Calendar?styleguideLive=true': ['37926:51369', '73747:14198'],
    // Cards [ru/en]
    '/#!/Components/Cards?styleguideLive=true': ['37926:57695', '73747:17338'],
    // Checkboxes [ru/en]
    '/#!/Components/Checkboxes?styleguideLive=true': ['37934:63837', '73747:19502'],
    // Chips [ru]
    '/#!/Components/Chips?styleguideLive=true': ['75320:11398'],
    // Date Picker [ru/en]
    '/#!/Components/DatePicker?styleguideLive=true': ['37934:66332', '73747:21955'],
    // Date Range [ru/en]
    '/#!/Components/DateRange?styleguideLive=true': ['71620:40887', '73747:20679'],
    // Divider [ru/en]
    '/#!/Components/Divider?styleguideLive=true': ['37937:68968', '73755:22921'],
    // Inputs [ru/en]
    '/#!/Components/Inputs?styleguideLive=true': ['14699:34852', '73756:25564'],
    // Labels [ru/en]
    '/#!/Components/Labels?styleguideLive=true': ['32949:1020', '73757:27986'],
    // LightBox [ru/en]
    '/#!/Components/LightBox/LightBox?styleguideLive=true': ['72283:43721', '73757:29265'],
    // Links [ru/en]
    '/#!/Components/Link?styleguideLive=true': ['15930:28210', '73757:30439'],
    // List [ru]
    '/#!/Components/List%20components?styleguideLive=true': ['75343:6617'],
    // MarkerStatus [ru/en]
    '/#!/Components/MarkerStatus?styleguideLive=true': ['34708:1', '73757:31045'],
    // ModalWindow [ru/en]
    '/#!/Components/ModalWindow/ModalWindow?styleguideLive=true': ['24603:39596', '73758:32280'],
    // Multiselect [ru/en]
    '/#!/Components/Multiselect?styleguideLive=true': ['35665:25153', '73758:34460'],
    // Notification Business [ru] Notification System [ru]
    '/#!/Components/Notification?styleguideLive=true': ['75656:3273', '74938:7240'],
    // Page [ru/en]
    '/#!/Components/Page?styleguideLive=true': ['37197:37261', '73759:38849'],
    // PageHeader [ru/en]
    '/#!/Components/HeaderPage?styleguideLive=true': ['14389:31970', '73756:26865'],
    // PageFooter [ru/en]
    '/#!/Components/FooterPage?styleguideLive=true': ['14962:33211', '73756:24524'],
    // Radio Group [ru/en]
    '/#!/Components/Radios?styleguideLive=true': ['30932:2890', '73759:39382'],
    // SegmentedControl [ru/en]
    '/#!/Components/SegmentedControl?styleguideLive=true': ['75334:8856', '73759:40250'],
    // Selects [ru/en]
    '/#!/Components/Selects?styleguideLive=true': ['34678:19084', '73759:41407'],
    // Sliders [ru/en]
    '/#!/Slider?styleguideLive=true': ['37099:32657', '73759:41957'],
    // Spoiler [ru/en]
    '/#!/Components/Spoiler?styleguideLive=true': ['34733:20225', '73759:42923'],
    // StatusTracker [ru/en]
    '/#!/Components/StatusTracker?styleguideLive=true': ['70446:45635', '73759:43907'],
    // Stepper [ru/en]
    '/#!/Components/Stepper?styleguideLive=true': ['70226:38416', '73759:45652'],
    // Suggest [ru/en]
    '/#!/Components/Suggest?styleguideLive=true': ['34698:1', '73759:47036'],
    // Table [ru/en]
    '/#!/Components/TableBasic?styleguideLive=true': ['75341:1039', '32879:0'],
    // Tabs [ru/en]
    '/#!/Components/Tabs?styleguideLive=true': ['38038:37281', '73759:48735'],
    // TabsFolder [ru/en]
    '/#!/Components/TabsFolder?styleguideLive=true': ['37903:38724', '73759:49603'],
    // TabsLine [ru]
    '/#!/Components/TabsLine?styleguideLive=true': ['75333:3010'],
    // Tag [ru/en]
    '/#!/Components/Tag?styleguideLive=true': ['34676:1', '73759:51281'],
    // TextField [ru/en]
    '/#!/Components/TextField?styleguideLive=true': ['72168:47453', '73759:52707'],
    // Tooltip [ru/en]
    '/#!/Components/Tooltip?styleguideLive=true': ['14964:33366', '73759:53976'],
    // UploadZone [ru/en]
    '/#!/Components/UploadZone?styleguideLive=true': ['36264:0', '73777:3917'],
    // Widget [ru/en]
    '/#!/Components/Widget?styleguideLive=true': ['74882:4914', '74189:1444'],

    // Раздел Super

    // Illustrations [ru]
    '/#!/Icons%20and%20Illustrations/Illustrations?styleguideLive=true': ['34939:12018'],
    // Icon Set. Design, Types, Principles.
    '/#!/Icons%20and%20Illustrations/Icons?styleguideLive=true': ['34751:0', '34866:11691', '34864:2'],
    // Skeleton [ru/en]
    '/#!/Components/Skeleton?styleguideLive=true': ['36285:12082', '71037:19336'],
}

const LivePreviewUtils = {
    styleguidistHost: process.env.REACT_APP_STYLEGUIDIST_HOST,

    // Возвращает путь демо-страницы для борда.
    getLiveDemoPathFromBoardId: (boardId: string): string | undefined => {
        let path;
        Object.keys(figmaBoardToStyleguidistComponentMap).some(key => {
            if (figmaBoardToStyleguidistComponentMap[key].includes(boardId)) {
                path = key;
                return true;
            }
        })

        return path;
    }
}

export default LivePreviewUtils;
